import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";

const API_SERVE = apiPath.equipment;
//查询润滑保养列表
export const getFlowData = params => {
  return axios.get(API_SERVE + "/page/tickets/approve", { params });
};

export const getExternalRepairs = params => {
  return axios.get(API_SERVE + "/select/maintenance/out/contractors", {
    params
  });
};
//查询备品备件
export const getCheckSpare = params => {
  return axios.get(API_SERVE + `/page/spareparts/itemmaster`, { params });
};

export const addExternalRepairs = params => {
  return axios.post(API_SERVE + "/add/maintenance/out", params);
};
// 查询外部维修部门
export const getExternalRepairsInfo = params => {
  return axios.get(apiPath.basics + "/list/contractor_persons", { params });
};

// 查询外部维修部门
export const getContractorsPersons = params => {
  return axios.get(apiPath.contractor + "/list/contractor_persons", { params });
};

// 更新外部维修记录
export const updateExternalRepairs = (id, params) => {
  return axios.post(API_SERVE + `/update/maintenance/out/${id}`, params);
};
// 删除外部维修记录
export const deleteExternalRepairs = id => {
  return axios.post(API_SERVE + `/del/maintenance/out/${id}`);
};
