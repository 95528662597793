<template>
  <!--新增外部维修部门-->
  <div class="page">
    <van-nav-bar
      fixed
      title="选择外部维修单位"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-checkbox-group v-model="result" class="checkbox">
        <template v-for="(item, idx) in externalRepairsList">
          <van-checkbox :key="idx" :name="item.value">{{
            item.label
          }}</van-checkbox>
        </template>
      </van-checkbox-group>
      <bottom-fixed-box>
        <van-button type="info" @click="save()">
          保存
        </van-button>
      </bottom-fixed-box>
    </div>
  </div>
</template>

<script>
import { getExternalRepairs, addExternalRepairs } from "./api";
import BottomFixedBox from "@/components/general/BottomFixedBox";

export default {
  name: "MaintenanceExternalRepairsAdd",
  components: {
    BottomFixedBox
  },
  props: {},
  data() {
    return {
      result: [],
      externalRepairsList: []
    };
  },
  created() {
    this.$nextTick(() => {
      this.getExternalRepairs();
    });
  },
  methods: {
    async save() {
      if (this.result.length < 1) {
        return;
      }
      let params = {
        ticketsMaintenanceRecordId: this.$route.params.recordId,
        ticketId: this.$route.params.workorderId,
        contractorIdList: this.result
      };
      await addExternalRepairs(params);
      this.$toast.success("保存成功！");
      this.$router.back();
    },
    getExternalRepairs() {
      getExternalRepairs({ recordId: this.$route.params.recordId }).then(
        res => {
          this.externalRepairsList = res;
        }
      );
    },
    navBack() {
      if (this.editable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;
}

.container {
  height: 100%;
  margin-top: 58px;
  overflow-y: auto;

  .checkbox {
    padding: 16px;

    .van-checkbox {
      margin-bottom: 10px;
    }
  }
}
</style>
